import {BoxProps, Collapse, Stack} from '@mui/material'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {CollapsibleCard} from '../../../shared/components/CollapsibleCard'
import {PlannerLSSettingsKeys} from '../../../shared/enums'
import {usePersistingSettingState} from '../../../shared/hooks/usePersistingSettingState'
import {StartRecalculationButton} from '../shared/StartRecalculationButton'

import {OptimizerPlansTable} from './OptimizerPlansTable'

export const OptimizerPlansOverviewCard = (props: BoxProps) => {
  const {t} = useTranslation()

  const [{isCardOpen}, setSettings] = usePersistingSettingState(
    PlannerLSSettingsKeys.OptimizerPageSettings,
    {isCardOpen: true}
  )

  const toggleIsMinimized = useCallback(() => {
    setSettings({isCardOpen: !isCardOpen})
  }, [setSettings, isCardOpen])

  return (
    <CollapsibleCard
      {...props}
      isCollapsed={!isCardOpen}
      onToggleCollapsed={toggleIsMinimized}
      title={t('optimizer.optimizedPlan')}
      headerContent={
        <Stack direction="row" sx={{justifyContent: 'flex-end'}}>
          <StartRecalculationButton backgroundMode="light" />
        </Stack>
      }
    >
      <Collapse in={isCardOpen}>
        <OptimizerPlansTable />
      </Collapse>
    </CollapsibleCard>
  )
}
