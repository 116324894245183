import {dataTestId} from '@hconnect/uikit/src/common'
import {Alert, HPButton} from '@hconnect/uikit/src/lib2'
import {Check, Close, Replay} from '@mui/icons-material'
import {Stack} from '@mui/material'
import {useCallback, useMemo} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useDialog} from '../../../shared/components/providers/DialogProvider'
import {useEditOptimizerSettings} from '../../../shared/hooks/api/optimizer/useEditOptimizerSettings'
import {useOptizerSettings} from '../../../shared/hooks/api/optimizer/useOptimizerSettings'

import {OptimizerPrioritiesLegend} from './OptimizerPrioritiesLegend'
import {OptimizerPrioritiesSlidersInput} from './OptimizerPrioritiesSlidersInput'
import {OptimizerPrioritiesTriangleInput} from './OptimizerPrioritiesTriangleInput'

export const OptimizerSettingsDialogContent = () => {
  const {t} = useTranslation()
  const {data: optimizerSettings} = useOptizerSettings()
  if (!optimizerSettings) {
    throw new Error('BUG: Optimizer settings should be loaded before opening the dialog')
  }

  const {editOptimizerSettings, isLoading} = useEditOptimizerSettings()

  const {
    control,
    reset,
    formState: {isDirty},
    handleSubmit
  } = useForm({
    defaultValues: {optimizerSettings},
    mode: 'onChange'
  })

  const {closeDialog} = useDialog()

  const submit = useMemo(
    () => handleSubmit(({optimizerSettings}) => editOptimizerSettings(optimizerSettings)),
    [handleSubmit, editOptimizerSettings]
  )

  const handleSaveSettings = useCallback(() => {
    void submit()
    closeDialog()
  }, [closeDialog, submit])

  const handleResetSettings = useCallback(() => reset(), [reset])

  const shouldDisableSaveButton = !isDirty || isLoading

  return (
    <Stack spacing={2} sx={{maxWidth: ({spacing}) => spacing(90)}}>
      <Alert
        accentColor="info"
        content={t('optimizer.optimizerSettingsInfo')}
        {...dataTestId('optimizer_settings_info')}
      />
      <Stack direction="row" spacing={2} sx={{width: 1}}>
        <Controller
          name="optimizerSettings"
          control={control}
          render={({field: {value, onChange}}) => (
            <OptimizerPrioritiesTriangleInput
              value={value}
              setValue={onChange}
              initialValue={optimizerSettings}
            />
          )}
        />
        <Stack spacing={2} sx={{alignSelf: 'center', flex: 1}}>
          <OptimizerPrioritiesLegend />
          <Controller
            name="optimizerSettings"
            control={control}
            render={({field: {value, onChange}}) => {
              return <OptimizerPrioritiesSlidersInput value={value} setValue={onChange} />
            }}
          />
        </Stack>
      </Stack>
      <Stack spacing={1.5} direction="row" justifyContent="space-between" sx={{width: 1}}>
        <HPButton
          {...dataTestId('reset_button')}
          backgroundMode="light"
          variant="text"
          startIcon={<Replay />}
          onClick={handleResetSettings}
          sx={{alignSelf: 'flex-start'}}
        >
          {t('optimizer.resetToDefault')}
        </HPButton>
        <Stack direction="row" spacing={1.5}>
          <HPButton
            {...dataTestId('cancel_button')}
            backgroundMode="light"
            variant="text"
            startIcon={<Close />}
            onClick={closeDialog}
          >
            {t('common.cancel')}
          </HPButton>
          <HPButton
            {...dataTestId('save_button')}
            variant="contained"
            backgroundMode="light"
            startIcon={<Check />}
            onClick={handleSaveSettings}
            disabled={shouldDisableSaveButton}
          >
            {t('common.save')}
          </HPButton>
        </Stack>
      </Stack>
    </Stack>
  )
}
