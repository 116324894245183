import {Skeleton, Typography} from '@mui/material'

import {CostAvoidanceForRangeData, useCostAvoidanceForRangeQuery} from '../../../shared/hooks/api'
import {usePlanningChartStartEnd} from '../../page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {OptimizerScheduleType} from '../shared/enums/optimizerSchedules'

import {OptimizerSchedule} from './hooks/useOptimizerPlansOverview'

const ScheduleKPICell: React.FC<{
  scheduleId: number
  render: (data: CostAvoidanceForRangeData) => React.ReactElement
}> = ({scheduleId, render}) => {
  const {startOfChart, endOfChart} = usePlanningChartStartEnd()
  const {data} = useCostAvoidanceForRangeQuery({
    scheduleId,
    from: startOfChart.toISOString(),
    to: endOfChart.toISOString()
  })

  if (!data) {
    return <Skeleton variant="text" />
  }

  return <Typography variant="h5">{render(data)}</Typography>
}

type OptimizerPlanTableKPICellParams = {
  schedule: OptimizerSchedule
  render: (data: CostAvoidanceForRangeData) => React.ReactElement
}

export const OptimizerPlanTableKPICell = ({schedule, render}: OptimizerPlanTableKPICellParams) => {
  if (schedule.type === OptimizerScheduleType.Calculating) {
    return <>-</>
  }

  return <ScheduleKPICell scheduleId={schedule.scheduleId} render={render} />
}
