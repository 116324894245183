import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {Popper, styled, useTheme} from '@mui/material'
import moment from 'moment-timezone'
import React, {FC, useMemo} from 'react'

import {useScheduleQuery} from '../../../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useCurrentTime} from '../../../../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {AssetWithOperationMode} from '../../../../../../shared/selectors/assets'
import {usePriceColors} from '../../../../../../shared/usePriceColors'
import {usePlanningChartScheduleData} from '../../../PlanningChartScheduleDataProvider'
import {usePlanningChartStartEnd} from '../../../PlanningChartStartEndProvider'

import {ScheduleItemPopperContent, ScheduleItemPopperState} from './ScheduleItemPopperContent'

const PriceIndicatorDiv = styled('div')(({theme}) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -1,
  transform: `translateY(${theme.spacing(0.5)})`
}))

interface ScheduleItemPopperProps {
  scheduleItemData: ScheduleItemPopperState
  assetHistoryDataByItemId: Record<string, AssetWithOperationMode>
  shouldShowFixedForOptimizerInfo: boolean
  shouldShowColorCodedElectricityPrices: boolean
  shouldShowExactElectricityPrices: boolean
}

const _ScheduleItemPopper: FC<ScheduleItemPopperProps> = ({
  scheduleItemData,
  assetHistoryDataByItemId,
  shouldShowFixedForOptimizerInfo,
  shouldShowColorCodedElectricityPrices,
  shouldShowExactElectricityPrices
}) => {
  const {timezone_id: timezoneId} = usePlantConfig()
  const theme = useTheme()
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const {scheduleId} = usePlanningChartScheduleData()
  const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], scheduleId})
  const {anchorEl, scheduleItem, priceLevel, consumption} = scheduleItemData

  const currentTime = useCurrentTime({timezoneId})
  const currentTimeRounded = useMemo(() => roundTo15MinIntervalStart(currentTime), [currentTime])

  const isHistoricalScheduleItem = moment.utc(scheduleItem.end).isSameOrBefore(currentTimeRounded)
  const priceIndicatorBackgroundColor = usePriceColors()[priceLevel]
  // for optimistic updates when id is generated on FE
  const assetData = assetHistoryDataByItemId?.[scheduleItem.id]
  if (!assetData) {
    return null
  }
  const {asset, operationMode} = assetData

  const shouldShowColorCodedPrice =
    shouldShowColorCodedElectricityPrices &&
    consumption !== 0 &&
    consumption !== null &&
    !isHistoricalScheduleItem

  // FIXME delete this line when BE is ready, jira ticket HCP-34769
  const isScheduleItemIdCorrect = !!(schedule?.schedules || {})[scheduleItem.id]
  return (
    <Popper
      open={Boolean(anchorEl) && isScheduleItemIdCorrect}
      anchorEl={anchorEl}
      placement="bottom"
      sx={{
        zIndex: 2,
        width: (theme) => theme.spacing(45)
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, parseInt(theme.spacing(1), 10)]
          }
        },
        {name: 'flip', enabled: true},
        {name: 'hide', enabled: true},
        {name: 'preventOverflow', enabled: true, options: {rootBoundary: 'viewport'}}
      ]}
    >
      <ScheduleItemPopperContent
        scheduleItemData={scheduleItemData}
        asset={asset}
        operationMode={operationMode}
        shouldShowFixedForOptimizerInfo={shouldShowFixedForOptimizerInfo}
        shouldShowColorCodedElectricityPrices={shouldShowColorCodedElectricityPrices}
        shouldShowExactElectricityPrices={shouldShowExactElectricityPrices}
      />
      {shouldShowColorCodedPrice && (
        <PriceIndicatorDiv sx={{backgroundColor: priceIndicatorBackgroundColor}} />
      )}
    </Popper>
  )
}

export const ScheduleItemPopper = React.memo(_ScheduleItemPopper)
