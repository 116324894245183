import {BarycentricSlidersInput, type BarycentricCoordinates} from '@hconnect/uikit/src/lib2'
import {FC} from 'react'

import {OptimizerSettings} from '../../../shared/interfaces/api'

import {
  mapBarycentricCoordinatesToOptimizerSettings,
  mapOptimizerSettingsToBarycentricCoordinates,
  useOptimizerPrioritiesStyles
} from './optimizerPriorities.helpers'

interface OptimizerPrioritiesSlidersInputProps {
  value: OptimizerSettings
  setValue: (value: OptimizerSettings) => void
}

export const OptimizerPrioritiesSlidersInput: FC<OptimizerPrioritiesSlidersInputProps> = ({
  value,
  setValue
}) => {
  const styles = useOptimizerPrioritiesStyles()

  return (
    <BarycentricSlidersInput
      value={mapOptimizerSettingsToBarycentricCoordinates(value)}
      setValue={(coordinates: BarycentricCoordinates) => {
        setValue(mapBarycentricCoordinatesToOptimizerSettings(coordinates))
      }}
      sliderConfig={styles}
    />
  )
}
