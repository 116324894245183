import {ReactComponent as NoOptimizedPlans} from '@hconnect/uikit/src/lib2/components/comment/noComments.svg'
import {BaseErrorPage} from '@hconnect/uikit/src/lib2/pages/BaseErrorPage'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router'

import {getUrl, PLANT_ROUTES, RouteName} from '../../../routing'
import {PlannerPageLayout} from '../../../shared/components/PlannerPageLayout'
import {pageDataTestId} from '../../../shared/formatutils'
import {useCurrentScheduleQuery} from '../../../shared/hooks/api/schedules/useCurrentScheduleQuery'
import {usePlanRange} from '../../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {StartRecalculationButton} from '../shared/StartRecalculationButton'

export const PageNoOptimizedPlans: React.FC = () => {
  const {t} = useTranslation()

  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const [startOfPlan, endOfPlan] = usePlanRange({timezoneId, createdAt})
  const {data: schedule} = useCurrentScheduleQuery({range: [startOfPlan, endOfPlan]})

  if (!schedule) {
    throw new Error('BUG: Schedule should be loaded before rendering this component')
  }

  if (schedule.isOptimizedScheduleAvailable) {
    return <Navigate to={getUrl(PLANT_ROUTES.OPTIMIZER.SUMMARY.path, {plantCode})} replace />
  }

  return (
    <PlannerPageLayout
      isTourBannerEnabled={true}
      title={t('navItems.optimizer')}
      pageTitle={t('navItems.optimizer')}
      sx={{display: 'flex', flexDirection: 'column'}}
      nameBodySx={{flexGrow: 1, justifyContent: 'center', alignContent: 'center'}}
      {...pageDataTestId(RouteName.OPTIMIZER_NO_PLANS)}
    >
      <BaseErrorPage
        illustration={<NoOptimizedPlans />}
        caption={t('optimizer.noOptimizedPlans')}
        description={t('optimizer.noOptimizedPlansDescription')}
        dataTestId="no_optimized_plans_placeholder"
      >
        <StartRecalculationButton backgroundMode="medium" />
      </BaseErrorPage>
    </PlannerPageLayout>
  )
}
