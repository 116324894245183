import {Stack} from '@mui/material'

import {OptimizerSettingsButton} from '../optimizerSettings/OptimizerSettingsButton'

import {SendToOptimizedPlanButton} from './SendToOptimizedPlanButton'

export const PageOptimizerActions = () => {
  return (
    <Stack direction="row" spacing={1.5}>
      <OptimizerSettingsButton />
      <SendToOptimizedPlanButton />
    </Stack>
  )
}
