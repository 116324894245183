import {Moment} from 'moment-timezone'
import React from 'react'

import {SelectedScheduleItemProvider} from '../../shared/components/providers/SelectedScheduleItemProvider'
import {PlannerSkeleton} from '../../shared/components/skeletons/PlannerSkeleton'
import {useHistoryAssetsQuery} from '../../shared/hooks/api'
import {PlanningChartScheduleDataProvider} from '../page-planning/dailyPlanning/PlanningChartScheduleDataProvider'

import {OptimizerDetailsChartContainer} from './optimizerDetails/OptimizerDetailsContainer'
import {useSelectedOptimizerPlan} from './SelectedOptimizerPlanProvider'

type LayoutOptimizerSummaryProps = {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const LayoutOptimizerSummary: React.FC<LayoutOptimizerSummaryProps> = ({
  startOfPlan,
  endOfPlan
}: LayoutOptimizerSummaryProps) => {
  const {visibleScheduleId} = useSelectedOptimizerPlan()

  const {data: sortedAssets} = useHistoryAssetsQuery({
    timeFrame: [startOfPlan, endOfPlan],
    sorted: true
  })

  return (
    <SelectedScheduleItemProvider>
      <div>
        <PlanningChartScheduleDataProvider scheduleId={visibleScheduleId}>
          {sortedAssets ? (
            <OptimizerDetailsChartContainer sortedAssets={sortedAssets} />
          ) : (
            <PlannerSkeleton height="40vh" />
          )}
        </PlanningChartScheduleDataProvider>
      </div>
    </SelectedScheduleItemProvider>
  )
}
