import {BarycentricTriangleInput, type BarycentricCoordinates} from '@hconnect/uikit/src/lib2'
import {FC} from 'react'

import {OptimizerSettings} from '../../../shared/interfaces/api'

import {
  mapBarycentricCoordinatesToOptimizerSettings,
  mapOptimizerSettingsToBarycentricCoordinates,
  useOptimizerPrioritiesStyles
} from './optimizerPriorities.helpers'

interface OptimizerPrioritiesTriangleInputProps {
  initialValue: OptimizerSettings
  value: OptimizerSettings
  setValue: (value: OptimizerSettings) => void
}

export const OptimizerPrioritiesTriangleInput: FC<OptimizerPrioritiesTriangleInputProps> = ({
  initialValue,
  value,
  setValue
}) => {
  const styles = useOptimizerPrioritiesStyles()

  return (
    <BarycentricTriangleInput
      initialValue={mapOptimizerSettingsToBarycentricCoordinates(initialValue)}
      value={mapOptimizerSettingsToBarycentricCoordinates(value)}
      setValue={(coordinates: BarycentricCoordinates) => {
        setValue(mapBarycentricCoordinatesToOptimizerSettings(coordinates))
      }}
      verticeStyles={styles}
    />
  )
}
