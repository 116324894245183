import React, {useCallback, useLayoutEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router'

import {getUrl, PLANT_ROUTES, RouteName} from '../../routing'
import {PlannerPageLayout} from '../../shared/components/PlannerPageLayout'
import {PlannerLSSettingsKeys} from '../../shared/enums'
import {pageDataTestId} from '../../shared/formatutils'
import {
  convertToURLDatetime,
  parseStartEndDatetimeFromURLParams
} from '../../shared/helpers/urlParams.utils'
import {calculateDatetimeRangeWithinBoundary} from '../../shared/helpers/utils'
import {useCurrentScheduleQuery} from '../../shared/hooks/api/schedules/useCurrentScheduleQuery'
import {usePlannerUISettings} from '../../shared/hooks/usePlannerUISettings'
import {usePlanRange} from '../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'
import {useSearchParams} from '../../shared/hooks/useSearchParams'
import {useUrlParam} from '../../shared/hooks/useUrlParam'
import {NumberRange} from '../../shared/selectors/time'
import {PlanningChartStartEndProvider} from '../page-planning/dailyPlanning/PlanningChartStartEndProvider'

import {LayoutOptimizerSummary} from './LayoutOptimizerSummary'
import {OptimizerSummaryPageSkeleton} from './OptimizerSummaryPageSkeleton'
import {PageOptimizerActions} from './plansOverview/PageOptimizerActions'
import {SelectedOptimizerPlanProvider} from './SelectedOptimizerPlanProvider'

export const MIN_RANGE_HOURS = 12
export const MAX_RANGE_HOURS = 24 * 7

export const PageOptimizerSummary: React.FC = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const [startOfPlan, endOfPlan] = usePlanRange({timezoneId, createdAt})
  const {data: schedule} = useCurrentScheduleQuery({range: [startOfPlan, endOfPlan]})

  // slider default range
  const {settings: sliderSettings, updateSettings: updateSliderSetting} = usePlannerUISettings(
    PlannerLSSettingsKeys.OptimizerSliderSettings,
    {defaultRange: 24}
  )
  const updateSliderDefaultRange = useCallback(
    (defaultRange: number) => {
      updateSliderSetting({defaultRange})
    },
    [updateSliderSetting]
  )

  const [obtainedParams, setSearchParams] = useSearchParams('start', 'end')

  const parsedSelectedRange = useMemo(() => {
    const parsedRange = parseStartEndDatetimeFromURLParams({...obtainedParams, timezoneId})
    // ensure that url params are within the operator view boundary
    return parsedRange
      ? calculateDatetimeRangeWithinBoundary(parsedRange, [startOfPlan, endOfPlan])
      : undefined
  }, [obtainedParams, startOfPlan, endOfPlan, timezoneId])

  const sliderDefaultRange = sliderSettings.defaultRange

  // if selectedRange is not set, set it to default selected range
  useLayoutEffect(() => {
    if (!parsedSelectedRange) {
      setSearchParams({
        start: convertToURLDatetime(startOfPlan, timezoneId),
        end: convertToURLDatetime(startOfPlan.clone().add(sliderDefaultRange, 'hours'), timezoneId)
      })
    }
  }, [parsedSelectedRange, setSearchParams, startOfPlan, sliderDefaultRange, timezoneId])

  const sliderMinMaxRange = useMemo(() => [MIN_RANGE_HOURS, MAX_RANGE_HOURS] as NumberRange, [])

  if (!parsedSelectedRange) {
    return <OptimizerSummaryPageSkeleton />
  }

  if (!schedule) {
    throw new Error('BUG: Schedule should be loaded before rendering this component')
  }

  if (!schedule.isOptimizedScheduleAvailable) {
    return <Navigate to={getUrl(PLANT_ROUTES.OPTIMIZER.NO_PLANS.path, {plantCode})} replace />
  }

  return (
    <PlanningChartStartEndProvider
      sliderDefaultRange={sliderDefaultRange}
      sliderMinMaxRange={sliderMinMaxRange}
      updateSliderDefaultRange={updateSliderDefaultRange}
      startOfPlan={startOfPlan}
      endOfPlan={endOfPlan}
      boundary={[startOfPlan, endOfPlan]}
    >
      <SelectedOptimizerPlanProvider>
        <PlannerPageLayout
          isTourBannerEnabled={true}
          title={t('navItems.optimizer')}
          pageTitle={t('navItems.optimizer')}
          headerActionContent={<PageOptimizerActions />}
          {...pageDataTestId(RouteName.OPTIMIZER)}
        >
          <LayoutOptimizerSummary startOfPlan={startOfPlan} endOfPlan={endOfPlan} />
        </PlannerPageLayout>
      </SelectedOptimizerPlanProvider>
    </PlanningChartStartEndProvider>
  )
}
