import React, {createContext, useContext, useMemo, useState} from 'react'

interface SelectedOptimizerPlanProviderState {
  visibleScheduleId: number | undefined
  setVisibleScheduleId: (scheduleId: number | undefined) => void
  selectedInTableScheduleId: number | undefined
  setSelectedInTableScheduleId: (scheduleId: number | undefined) => void
}

const SelectedOptimizerPlanContext = createContext<SelectedOptimizerPlanProviderState | undefined>(
  undefined
)

export const useSelectedOptimizerPlan = () => {
  const context = useContext(SelectedOptimizerPlanContext)
  if (!context) {
    throw new Error('useSelectedOptimizerPlan must be used within a SelectedOptimizerPlanProvider')
  }
  return context
}

export const SelectedOptimizerPlanProvider: React.FC<{children: React.ReactNode}> = ({
  children
}) => {
  // if visibleScheduleId is undefined that means we are should see a manual production plan
  const [visibleScheduleId, setVisibleScheduleId] = useState<number>()
  // if selectedInTableScheduleId is undefined we should see a manual production plan selected in the table
  const [selectedInTableScheduleId, setSelectedInTableScheduleId] = useState<number>()

  const memoizedState = useMemo<SelectedOptimizerPlanProviderState>(
    () => ({
      visibleScheduleId,
      setVisibleScheduleId,
      selectedInTableScheduleId,
      setSelectedInTableScheduleId
    }),
    [
      visibleScheduleId,
      setVisibleScheduleId,
      selectedInTableScheduleId,
      setSelectedInTableScheduleId
    ]
  )

  return (
    <SelectedOptimizerPlanContext.Provider value={memoizedState}>
      {children}
    </SelectedOptimizerPlanContext.Provider>
  )
}
