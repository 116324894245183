import {dataTestId} from '@hconnect/uikit'
import {CardBox, useGuidedTours} from '@hconnect/uikit/src/lib2'
import AutoAwesome from '@mui/icons-material/AutoAwesome'
import {Box, Typography, Button, styled} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {useCurrentScheduleQuery} from '../../../shared/hooks/api/schedules/useCurrentScheduleQuery'

const StyledArrowLeft = styled('div')(({theme}) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '-8px',
  width: 0,
  height: 0,
  borderTop: '8px solid transparent',
  borderBottom: '8px solid transparent',
  borderRight: `8px solid ${theme.palette.background.paper}`
}))

export const OptimizerNotification: React.FC = () => {
  const {t} = useTranslation()
  const {setActiveTour} = useGuidedTours()
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('Timeframe should be selected to query a schedule')
  }
  const {data: schedule} = useCurrentScheduleQuery({
    range: selectedTimeframe,
    useErrorBoundary: false
  })
  if (!schedule || !schedule.isOptimizedScheduleAvailable) return <div />
  return (
    <CardBox
      sx={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        ml: 2,
        left: '100%',
        top: '-75%',
        zIndex: 2,
        width: 'max-content'
      }}
      {...dataTestId('optimizer_notification')}
    >
      <AutoAwesome color="primary" />
      <Box sx={{m: 0.25}} />
      <Typography variant="h4">{t('planning.optimizedPlanAvailable')}</Typography>
      <Box sx={{m: 1}} />
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setActiveTour('newOptimizerTour')
        }}
        {...dataTestId('optimizer_notification_button')}
      >
        {t('planning.viewSummary')}
      </Button>
      <StyledArrowLeft />
    </CardBox>
  )
}
