import {waitForDOMElementToAppear} from '@hconnect/uikit/src'
import type {GuidedTour, GuidedTourStep} from '@hconnect/uikit/src/lib2/components/guidedTour/types'
import {AutoAwesome} from '@mui/icons-material'
import {Stack} from '@mui/material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Illustration} from '../assets/images/Onboarding_illustration.svg'
import {PLANT_ROUTES, getUrl} from '../routing'
import {PlannerFeature} from '../shared/enums'
import {usePlannerFeature} from '../shared/hooks/usePlannerFeature'
import {useUrlParam} from '../shared/hooks/useUrlParam'

const getStepTranslationKey = (index: string) => `guidedTours.costAvoidanceTour.steps.${index}`

export const usePlannerTours = (): Record<string, GuidedTour> => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const canPurchaseElectricity = usePlannerFeature(PlannerFeature.ElectricityPurchasing)
  const plantCode = useUrlParam('plantCode')

  return useMemo((): Record<string, GuidedTour> => {
    const plannerCostAvoidanceTourFirstStep: GuidedTourStep = canPurchaseElectricity
      ? {
          target: '#submit-plan-button',
          content: t(getStepTranslationKey('1_plant_with_submissions')),
          placement: 'left'
        }
      : {
          target: '#calculate-cost-avoidance',
          content: t(getStepTranslationKey('1_plant_without_submission')),
          placement: 'left'
        }

    return {
      plannerCostAvoidanceTour: {
        title: t('guidedTours.costAvoidanceTour.title'),
        description: t('guidedTours.costAvoidanceTour.description'),
        onTourStarted: async () => {
          navigate(getUrl(PLANT_ROUTES.PRODUCTION_PLANNING.path, {plantCode}))
          await Promise.all([
            waitForDOMElementToAppear(plannerCostAvoidanceTourFirstStep.target),
            // waiting for the electricity chart to calculate correct highlight position
            waitForDOMElementToAppear('#electricity-consumption-chart')
          ])
        },
        image: <Illustration />,
        steps: [
          plannerCostAvoidanceTourFirstStep,
          {
            target: plannerCostAvoidanceTourFirstStep.target,
            content: t(getStepTranslationKey('2')),
            placement: 'left',
            onGoPrevToStep: async () => {
              navigate(getUrl(PLANT_ROUTES.PRODUCTION_PLANNING.path, {plantCode}))
              await waitForDOMElementToAppear(plannerCostAvoidanceTourFirstStep.target)
            }
          },
          {
            target: '#electricity-cost-avoidance-title',
            content: t(getStepTranslationKey('3')),
            placement: 'right',
            onGoNextToStep: async () => {
              navigate(getUrl(PLANT_ROUTES.ELECTRICITY_FORECAST.path, {plantCode}))
              // waiting for the elements to calculate correct highlight position
              await Promise.all([
                waitForDOMElementToAppear('#cost-avoidance-monthly-chart-container'),
                waitForDOMElementToAppear('#monthly-cost-avoidance-table')
              ])
            }
          },
          {
            target: '#cost-avoidance-chart-legend-optimizer-used',
            content: t(getStepTranslationKey('4')),
            placement: 'left'
          },
          {
            target: '#montly-cost-avoidance-table-header-percent-of-total',
            content: t(getStepTranslationKey('5')),
            placement: 'left'
          },
          {
            target: '#montly-cost-avoidance-table-header-percent-of-year',
            content: t(getStepTranslationKey('6')),
            placement: 'left'
          },
          {
            target: '#monthly-cost-avoidance-table .datatable-row-01',
            content: t(getStepTranslationKey('7')),
            placement: 'left'
          }
        ]
      },
      newOptimizerTour: {
        // since this tour is excluded from banner, we don't need to provide this information
        title: '',
        description: '',
        image: <></>,
        steps: [
          {
            target: '#nav_item_optimizer',
            title: (
              <Stack direction="row" spacing={1}>
                <AutoAwesome />
                <span>{t('guidedTours.newOptimizerTour.title')}</span>
              </Stack>
            ),
            content: t('guidedTours.newOptimizerTour.description'),
            placement: 'bottom'
          }
        ]
      }
    }
  }, [navigate, t, plantCode, canPurchaseElectricity])
}
