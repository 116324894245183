import {AssetSnapshot} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Box, Collapse, Stack} from '@mui/material'
import {t} from 'i18next'
import moment, {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CollapsibleCard} from '../../../shared/components/CollapsibleCard'
import {PlanningChartGantt} from '../../../shared/components/ganttChart/PlanningChartGantt'
import {PlannerLSSettingsKeys} from '../../../shared/enums'
import {TimeScaleFn} from '../../../shared/helpers/scale'
import {toPixel} from '../../../shared/helpers/utils'
import {useScheduleQuery} from '../../../shared/hooks/api/schedules/useScheduleQuery'
import {usePersistingSettingState} from '../../../shared/hooks/usePersistingSettingState'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {isOptimizedScheduleGuard} from '../../../shared/interfaces/api'
import {getLocalizedDayMonthTimeFormat} from '../../../shared/selectors/time'
import {PlanningChartScheduleDataProvider} from '../../page-planning/dailyPlanning/PlanningChartScheduleDataProvider'
import {PlanningChartLabels} from '../../page-planning/dailyPlanning/scheduleCard/PlanningChartLabels'
import {PlanningChartLegend} from '../../page-planning/dailyPlanning/scheduleCard/PlanningChartLegend'
import {useSelectedOptimizerPlan} from '../SelectedOptimizerPlanProvider'

import {TogglePlanTypeButton} from './TogglePlanTypeButton'

interface OptimizerPlanningChartCardProps {
  startOfPlan: Moment
  endOfPlan: Moment
  dateLabelHeight: number
  hourlyPriceHeight: number
  labelColumnWidth: number
  cellHeight: number
  cellWidth: number
  hoursDisplayed: number
  sortedAssets: AssetSnapshot[]
  xScale: TimeScaleFn
  hoursList: Moment[]
}

export const _OptimizerPlanningChartCard = React.forwardRef<
  HTMLDivElement,
  OptimizerPlanningChartCardProps
>(
  (
    {
      startOfPlan,
      endOfPlan,
      dateLabelHeight,
      hourlyPriceHeight,
      labelColumnWidth,
      cellHeight,
      cellWidth,
      hoursDisplayed,
      xScale,
      hoursList,
      sortedAssets
    },
    ref
  ) => {
    const {timezone_id: timezoneId} = usePlantConfig()
    const {
      i18n: {language}
    } = useTranslation()

    const {visibleScheduleId} = useSelectedOptimizerPlan()

    const {data: schedule} = useScheduleQuery({
      range: [startOfPlan, endOfPlan],
      scheduleId: visibleScheduleId
    })

    const scheduleLastUpdatedAt = isOptimizedScheduleGuard(schedule)
      ? schedule?.optimizationDetails.triggeredOn
      : schedule?.lastSaved.savedAt
    const scheduleLastUpdatedBy = isOptimizedScheduleGuard(schedule)
      ? schedule?.optimizationDetails.triggeredBy
      : schedule?.lastSaved.savedBy

    const [{isCardOpen}, setIsPlanningCardOpen] = usePersistingSettingState(
      PlannerLSSettingsKeys.PlanningChartCardSettings,
      {isCardOpen: true}
    )
    const toggleCardOpen = () => setIsPlanningCardOpen({isCardOpen: !isCardOpen})

    return (
      <CollapsibleCard
        isCollapsed={!isCardOpen}
        onToggleCollapsed={toggleCardOpen}
        title={t('planning.planningSectionTitle')}
        subtitle={
          scheduleLastUpdatedAt &&
          scheduleLastUpdatedBy &&
          t('planning.lastSaved', {
            date: getLocalizedDayMonthTimeFormat(
              moment.utc(scheduleLastUpdatedAt),
              timezoneId,
              language
            ),
            name: scheduleLastUpdatedBy
          })
        }
        headerContent={
          <Stack direction="row" sx={{justifyContent: 'flex-end'}}>
            <TogglePlanTypeButton />
          </Stack>
        }
        {...dataTestId('optimizer_planning_chart_card')}
      >
        <Collapse in={isCardOpen}>
          <PlanningChartScheduleDataProvider scheduleId={visibleScheduleId}>
            <Stack
              sx={{position: 'relative', overflow: 'hidden'}}
              spacing={2}
              {...dataTestId('optimizer_planning_chart_card_content')}
            >
              <PlanningChartLegend
                shouldShowActualPricesLegend
                startOfPlan={startOfPlan}
                endOfPlan={endOfPlan}
              />
              <Stack direction="row">
                <Box sx={{mt: toPixel(2 * dateLabelHeight)}}>
                  <PlanningChartLabels
                    sortedAssets={sortedAssets}
                    hourlyPriceHeight={hourlyPriceHeight}
                    cellHeight={cellHeight}
                    width={labelColumnWidth}
                    isReadOnly={true}
                    shouldShowPriceInfo={true}
                  />
                </Box>
                <PlanningChartGantt
                  ref={ref}
                  startOfPlan={startOfPlan}
                  endOfPlan={endOfPlan}
                  numberOfAssets={sortedAssets.length}
                  dateLabelHeight={dateLabelHeight}
                  hourlyPriceHeight={hourlyPriceHeight}
                  cellHeight={cellHeight}
                  cellWidth={cellWidth}
                  hoursDisplayed={hoursDisplayed}
                  xScale={xScale}
                  hoursList={hoursList}
                  isReadOnly={true}
                  shouldRoundCurrentTime
                  shouldShowFixedForOptimizerInfo
                  shouldShowExactPrices
                  shouldShowActualPricesIndicator
                  shouldShowColorCodedElectricityPrices
                />
              </Stack>
            </Stack>
          </PlanningChartScheduleDataProvider>
        </Collapse>
      </CollapsibleCard>
    )
  }
)
export const OptimizerPlanningChartCard = React.memo(_OptimizerPlanningChartCard)
OptimizerPlanningChartCard.displayName = 'OptimizerPlanningChartCard'
