import {Dialog} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'

import {useScheduleCostAvoidanceDialog} from '../../../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {useCurrentScheduleQuery} from '../../../../shared/hooks/api/schedules/useCurrentScheduleQuery'

import {CostAvoidanceDialogContent} from './CostAvoidanceDialogContent'

interface CostAvoidanceDialogProps {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const CostAvoidanceDialog: React.FC<CostAvoidanceDialogProps> = ({
  startOfPlan,
  endOfPlan
}) => {
  const {isDialogOpen} = useScheduleCostAvoidanceDialog()
  const {data: schedule} = useCurrentScheduleQuery({range: [startOfPlan, endOfPlan]})
  const scheduleId = schedule?.scheduleId

  if (!scheduleId) {
    return null
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogOpen}>
      <CostAvoidanceDialogContent scheduleId={scheduleId} />
    </Dialog>
  )
}
