import {dataTestId} from '@hconnect/uikit'
import {Paper, Skeleton} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {PlannerPageLayout} from '../../shared/components/PlannerPageLayout'

const SKELETON_HEIGHT = '65vh'

export function OptimizerSummaryPageSkeleton() {
  const {t} = useTranslation()

  return (
    <PlannerPageLayout
      isTourBannerEnabled={false}
      title={t('navItems.optimizer')}
      pageTitle={t('navItems.optimizer')}
      {...dataTestId('optimizer_page_skeleton')}
    >
      <Skeleton
        variant="rectangular"
        component={Paper}
        sx={{
          height: SKELETON_HEIGHT,
          backgroundColor: ({palette}) => palette.background.paper
        }}
      />
    </PlannerPageLayout>
  )
}
