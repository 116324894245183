import {dataTestId} from '@hconnect/uikit'
import {Skeleton} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CollapsibleCard} from '../../../../shared/components/CollapsibleCard'
import {PlannerLSSettingsKeys} from '../../../../shared/enums'
import {useCostAvoidanceForRangeQuery} from '../../../../shared/hooks/api'
import {useScheduleQuery} from '../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useDebouncedValue} from '../../../../shared/hooks/useDebouncedValue'
import {usePersistingSettingState} from '../../../../shared/hooks/usePersistingSettingState'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {getLocalizedDayMonthTimeFormat} from '../../../../shared/selectors/time'
import {usePlanningChartScheduleData} from '../PlanningChartScheduleDataProvider'
import {usePlanningChartStartEnd} from '../PlanningChartStartEndProvider'

import {KPISectionTable} from './KPIsSectionTable'

type KPIsSectionSubtitleProps = {
  scheduleId: number
  from: Moment
  to: Moment
}

const KPIsSectionSubtitle: React.FC<KPIsSectionSubtitleProps> = ({scheduleId, from, to}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()

  const {dataUpdatedAt} = useCostAvoidanceForRangeQuery({
    scheduleId,
    from: moment.utc(from).toISOString(),
    to: moment.utc(to).toISOString()
  })
  const tableUpdatedAt = moment.utc(dataUpdatedAt)

  return (
    <>
      {t('common.lastUpdate', {
        date: getLocalizedDayMonthTimeFormat(tableUpdatedAt, timezoneId, language)
      })}
    </>
  )
}

export const KPIsSection: React.FC = () => {
  const {t} = useTranslation()
  const {startOfChart, endOfChart, startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const {scheduleId} = usePlanningChartScheduleData()
  const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], scheduleId})
  const [{isCardOpen}, setIsCardOpen] = usePersistingSettingState(
    PlannerLSSettingsKeys.PlanningKPITableSettings,
    {isCardOpen: false}
  )

  const toggleIsKPITableMinimized = useCallback(
    () => setIsCardOpen({isCardOpen: !isCardOpen}),
    [setIsCardOpen, isCardOpen]
  )

  // create object containing both startOfChart and endOfChart so I can debounce both values as single object in lane below
  const fromTo = useMemo(() => ({startOfChart, endOfChart}), [startOfChart, endOfChart])
  const {startOfChart: debouncedStartOfChart, endOfChart: debouncedEndOfChart} = useDebouncedValue({
    value: fromTo,
    wait: 500
  }).debouncedValue

  return (
    <CollapsibleCard
      isCollapsed={!isCardOpen}
      onToggleCollapsed={toggleIsKPITableMinimized}
      title={t('planning.kpiTable.kpiCostAndProduction')}
      subtitle={
        schedule ? (
          <KPIsSectionSubtitle
            scheduleId={schedule.scheduleId}
            from={debouncedStartOfChart}
            to={debouncedEndOfChart}
          />
        ) : (
          <Skeleton variant="text" sx={{width: ({spacing}) => spacing(30)}} />
        )
      }
      {...dataTestId('kpi_card')}
    >
      {schedule ? (
        <KPISectionTable
          scheduleId={schedule.scheduleId}
          isExpanded={isCardOpen}
          from={debouncedStartOfChart}
          to={debouncedEndOfChart}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{width: 1, height: ({spacing}) => spacing(isCardOpen ? 30 : 5)}}
        />
      )}
    </CollapsibleCard>
  )
}
