import {dataTestId} from '@hconnect/uikit'
import {CancelOutlined} from '@mui/icons-material'
import {Stack, CircularProgress, Typography, IconButton} from '@mui/material'
import moment from 'moment-timezone'
import {useTranslation} from 'react-i18next'

import {useCancelOptimizerRun} from '../../../shared/hooks/api'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {getLocalizedDayMonthTimeFormat} from '../../../shared/selectors/time'
import {OptimizerScheduleType} from '../shared/enums/optimizerSchedules'

import {OptimizerSchedule} from './hooks/useOptimizerPlansOverview'

export function OptimizerPlansPlanNameCell({schedule}: {schedule: OptimizerSchedule}) {
  const {timezone_id: timezoneId} = usePlantConfig()
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {cancelOptimizerRun, isLoading} = useCancelOptimizerRun()

  if (schedule.type === OptimizerScheduleType.Manual) {
    const {
      lastSaved: {savedBy, savedAt}
    } = schedule
    return (
      <>
        {t('optimizer.manualScheduleName', {
          savedBy: savedBy,
          savedOn: getLocalizedDayMonthTimeFormat(moment.utc(savedAt), timezoneId, language)
        })}
      </>
    )
  }

  if (schedule.type === OptimizerScheduleType.Optimized) {
    const {
      optimizationDetails: {triggeredBy, triggeredOn, name}
    } = schedule

    return (
      <>
        {t('optimizer.optimizerScheduleName', {
          name: name,
          user: triggeredBy,
          date: getLocalizedDayMonthTimeFormat(moment.utc(triggeredOn), timezoneId, language)
        })}
      </>
    )
  }

  const {queuedAt, queuedBy, name} = schedule

  return (
    <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
      <CircularProgress size={16} />
      <Stack>
        <span>
          {t('optimizer.optimizerScheduleName', {
            name: name,
            user: queuedBy,
            date: getLocalizedDayMonthTimeFormat(moment.utc(queuedAt), timezoneId, language)
          })}
        </span>
        <Typography variant="caption">{t('optimizer.calculating')}</Typography>
      </Stack>
      <IconButton
        {...dataTestId('cancel_optimizer_run_button')}
        onClick={() => cancelOptimizerRun(schedule.optimizerRunId)}
        disabled={isLoading}
      >
        <CancelOutlined fontSize="small" />
      </IconButton>
    </Stack>
  )
}
