import {useMemo} from 'react'

import {useCalculatingPlans} from '../../../../shared/hooks/api/optimizer/useCalculatingPlans'
import {useCurrentScheduleQuery} from '../../../../shared/hooks/api/schedules/useCurrentScheduleQuery'
import {useOptimizedSchedulesQuery} from '../../../../shared/hooks/api/schedules/useOptimizedSchedulesQuery'
import {usePlanRange} from '../../../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {OptimizerRun, OptimizedSchedule, Schedule} from '../../../../shared/interfaces/api'
import {OptimizerScheduleType} from '../../shared/enums/optimizerSchedules'

export type OptimizerSchedule =
  | ({type: OptimizerScheduleType.Manual} & Schedule)
  | ({type: OptimizerScheduleType.Optimized} & OptimizedSchedule)
  | ({type: OptimizerScheduleType.Calculating} & OptimizerRun)

export function useOptimizerPlanOverview() {
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const [startOfPlan, endOfPlan] = usePlanRange({timezoneId, createdAt})

  const {data: manualSchedule} = useCurrentScheduleQuery({range: [startOfPlan, endOfPlan]})
  const {data: optimizedSchedules} = useOptimizedSchedulesQuery()
  const {data: calculatingPlans} = useCalculatingPlans()

  return useMemo<OptimizerSchedule[]>(
    () =>
      [
        manualSchedule ? {...manualSchedule, type: OptimizerScheduleType.Manual} : undefined,
        ...(optimizedSchedules || []).map((schedule) => ({
          ...schedule,
          type: OptimizerScheduleType.Optimized
        })),
        ...(calculatingPlans ?? []).map((plan) => ({
          ...plan,
          type: OptimizerScheduleType.Calculating
        }))
      ].filter(Boolean) as OptimizerSchedule[],
    [manualSchedule, optimizedSchedules, calculatingPlans]
  )
}
