import React, {useContext, useMemo} from 'react'

interface PlanningChartScheduleDataState {
  // todo: change to real scheduleId and maybe think of some separate state for the currently used schedule?
  scheduleId?: number
}

const PlanningChartScheduleDataContext = React.createContext<
  PlanningChartScheduleDataState | undefined
>(undefined)

export const usePlanningChartScheduleData = () => {
  const context = useContext(PlanningChartScheduleDataContext)
  if (!context) {
    throw new Error(
      'Cannot use PlanningChartScheduleDataContext outside of a PlanningChartScheduleDataProvider'
    )
  }
  return context
}

interface PlanningChartScheduleDataProviderProps {
  scheduleId?: number
  children: React.ReactNode
}
export const PlanningChartScheduleDataProvider = ({
  children,
  scheduleId
}: PlanningChartScheduleDataProviderProps) => {
  const memoizedState: PlanningChartScheduleDataState = useMemo(
    () => ({
      scheduleId
    }),
    [scheduleId]
  )

  return (
    <PlanningChartScheduleDataContext.Provider value={memoizedState}>
      {children}
    </PlanningChartScheduleDataContext.Provider>
  )
}
