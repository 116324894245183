import {Grid} from '@mui/material'
import {useMemo} from 'react'

import {SiloLevelsSkeleton} from '../../../shared/components/skeletons/SiloLevelsSkeleton'
import {
  useCurrentStoragesWithMaterialQuery,
  useHistoryAssetsQuery,
  useMaterialsRecipesById
} from '../../../shared/hooks/api'
import {useHistoryMaterialsById} from '../../../shared/hooks/api/materials/useHistoryMaterialsById'
import {getOptimizedMaterialIdsWithStorages} from '../../../shared/selectors/storageLevels'
import {usePlanningChartStartEnd} from '../../page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {SiloLevelsCard} from '../SiloLevelsCard'

const getSiloLevelsSkeletons = (amount: number) => {
  return Array.from({length: amount}, (_, index) => (
    <Grid item sm={12} lg={6} key={index}>
      <SiloLevelsSkeleton />
    </Grid>
  ))
}

export const OptimizerDetailsOldCharts = () => {
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()

  const {data: storagesWithMaterial} = useCurrentStoragesWithMaterialQuery()
  const {data: assets} = useHistoryAssetsQuery({timeFrame: [startOfPlan, endOfPlan]})
  const {data: materialsById} = useHistoryMaterialsById({timeFrame: [startOfPlan, endOfPlan]})
  const {data: recipesById} = useMaterialsRecipesById()
  const optimizedMaterialIds = useMemo(
    () =>
      assets && recipesById && storagesWithMaterial
        ? getOptimizedMaterialIdsWithStorages({assets, recipesById, storagesWithMaterial})
        : undefined,
    [recipesById, assets, storagesWithMaterial]
  )

  const shouldShowLoader = materialsById && optimizedMaterialIds

  return (
    <>
      <Grid container spacing={2} mt={1}>
        {shouldShowLoader
          ? optimizedMaterialIds.map((materialId, index) => (
              <Grid item xs={12} lg={6} key={`${materialId}_${index}_card`}>
                <SiloLevelsCard material={materialsById[materialId]} />
              </Grid>
            ))
          : getSiloLevelsSkeletons(6)}
      </Grid>
    </>
  )
}
