import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useState, useMemo} from 'react'

import {AmpelPriceLevels} from '../../../../../shared/ampel'
import {LinearScaleFn, TimeScaleFn} from '../../../../../shared/helpers/scale'
import {
  useScheduleCommentsByScheduleItemId,
  useHistoryAssetsQuery,
  useHistoryStoragesByIdQuery
} from '../../../../../shared/hooks/api'
import {useScheduleQuery} from '../../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useSubmitSchedule} from '../../../../../shared/hooks/api/schedules/useSubmitSchedule'
import {useUpdateScheduleItem} from '../../../../../shared/hooks/api/schedules/useUpdateScheduleItem'
import {useAssetHistoryDataByScheduleItemId} from '../../../../../shared/hooks/api/useAssetHistoryDataByScheduleItemId'
import {usePlantConfig} from '../../../../../shared/hooks/usePlantConfigData'
import {DatetimeValue} from '../../../../../shared/interfaces/common'
import {usePlanningChartScheduleData} from '../../PlanningChartScheduleDataProvider'
import {usePlanningChartStartEnd} from '../../PlanningChartStartEndProvider'

import {EditScheduleItemDialog} from './editDialog/EditScheduleItemDialog'
import {ScheduleItemPopper} from './popper'
import {ScheduleItemPopperState} from './popper/ScheduleItemPopperContent'
import {ScheduleItem} from './ScheduleItem'

interface ScheduleItemsProps {
  startOfPlan: Moment
  endOfPlan: Moment
  priceLevels: AmpelPriceLevels
  prices: DatetimeValue<Moment>[]
  scheduleRowHeight: number
  scheduleRowPadding: number
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  isReadOnly: boolean
  shouldShowFixedForOptimizerInfo: boolean
  shouldShowColorCodedElectricityPrices: boolean
  shouldShowExactElectricityPrices: boolean
  shouldShowStartEndTime: boolean
  shouldShowScheduleItemComments: boolean
}

const _ScheduleItemsContainer: React.FC<ScheduleItemsProps> = ({
  startOfPlan,
  endOfPlan,
  priceLevels,
  prices,
  scheduleRowHeight,
  scheduleRowPadding,
  xScale,
  yScale,
  isReadOnly,
  shouldShowFixedForOptimizerInfo,
  shouldShowColorCodedElectricityPrices,
  shouldShowExactElectricityPrices,
  shouldShowStartEndTime,
  shouldShowScheduleItemComments
}) => {
  const plantConfig = usePlantConfig()
  const {scheduleId} = usePlanningChartScheduleData()
  const {data: schedule, isFetching: isScheduleFetching} = useScheduleQuery({
    range: [startOfPlan, endOfPlan],
    scheduleId
  })
  const assetDataByScheduleItemId = useAssetHistoryDataByScheduleItemId({
    timeFrame: [startOfPlan, endOfPlan],
    schedule
  })
  const {isLoading: isScheduleSubmitting} = useSubmitSchedule()

  const {updateScheduleItem} = useUpdateScheduleItem({
    startOfPlan,
    endOfPlan,
    assetDataByScheduleItemId
  })

  const {startOfChart, endOfChart} = usePlanningChartStartEnd()

  const scheduleItems = Object.values(schedule?.schedules ?? {})

  const {data: sortedAssets} = useHistoryAssetsQuery({
    timeFrame: [startOfPlan, endOfPlan],
    sorted: true
  })

  const {data: storagesById} = useHistoryStoragesByIdQuery({
    timeFrame: [startOfPlan, endOfPlan],
    useErrorBoundary: false
  })

  const isScheduleItemReadOnly = isReadOnly || isScheduleFetching || isScheduleSubmitting

  const [scheduleItemPopperState, setScheduleItemPopperState] = useState<ScheduleItemPopperState>()

  const scheduleItemsIds = (shouldShowScheduleItemComments ? scheduleItems : []).map(({id}) => id)

  const {data: commentsByScheduleItemId} = useScheduleCommentsByScheduleItemId(
    scheduleItemsIds,
    !shouldShowScheduleItemComments
  )

  const sortedAssetsIds = useMemo(() => sortedAssets?.map(({id}) => id), [sortedAssets])

  const canShowPopper = assetDataByScheduleItemId && scheduleItemPopperState && storagesById
  const canShowScheduleItems = sortedAssetsIds && assetDataByScheduleItemId

  // filter only visible range ( DOM virtualization of schedule items)
  const visibleScheduleItems = useMemo(() => {
    return scheduleItems.filter(
      (item) => endOfChart.isSameOrAfter(item.start) && startOfChart.isSameOrBefore(item.end)
    )
  }, [scheduleItems, startOfChart, endOfChart])
  return (
    <>
      <Box sx={{position: 'absolute', zIndex: 1}}>
        {canShowScheduleItems
          ? visibleScheduleItems.map((item) => {
              const foundComment = commentsByScheduleItemId?.[item.id]
              const rowIndex = sortedAssetsIds.findIndex((assetId) => assetId === item.assetId)
              const assetData = assetDataByScheduleItemId[item.id]
              const assetType = assetData.asset.type
              return (
                <ScheduleItem
                  key={`schedule_item_${item.id}`}
                  timezoneId={plantConfig.timezone_id}
                  startOfPlan={startOfPlan}
                  endOfPlan={endOfPlan}
                  item={item}
                  scheduleRowHeight={scheduleRowHeight}
                  scheduleRowPadding={scheduleRowPadding}
                  xScale={xScale}
                  yScale={yScale}
                  priceLevels={priceLevels}
                  prices={prices}
                  assetType={assetType}
                  operationMode={assetData.operationMode}
                  comment={foundComment}
                  setScheduleItemPopperOpen={setScheduleItemPopperState}
                  updateScheduleItem={updateScheduleItem}
                  isReadOnly={isScheduleItemReadOnly}
                  rowIndex={rowIndex}
                  shouldShowStartEndTimeLabels={shouldShowStartEndTime}
                  enableColorCodedElectricityPrices={shouldShowColorCodedElectricityPrices}
                  enableExactElectricityPrices={shouldShowExactElectricityPrices}
                />
              )
            })
          : undefined}
      </Box>
      {canShowPopper && (
        <ScheduleItemPopper
          scheduleItemData={scheduleItemPopperState}
          assetHistoryDataByItemId={assetDataByScheduleItemId}
          shouldShowFixedForOptimizerInfo={shouldShowFixedForOptimizerInfo}
          shouldShowColorCodedElectricityPrices={shouldShowColorCodedElectricityPrices}
          shouldShowExactElectricityPrices={shouldShowExactElectricityPrices}
        />
      )}
      <EditScheduleItemDialog
        startOfPlan={startOfPlan}
        endOfPlan={endOfPlan}
        commentsByItemId={commentsByScheduleItemId}
      />
    </>
  )
}
export const ScheduleItemsContainer = React.memo(_ScheduleItemsContainer)
ScheduleItemsContainer.displayName = 'ScheduleItemsContainer'
