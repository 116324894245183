import {dataTestId as spreadDataTestId} from '@hconnect/uikit'
import {DayRangeSlider, SliderKey} from '@hconnect/uikit/src/lib2/components/rangeSliders'
import {useTheme, useMediaQuery} from '@mui/material'
import {throttle} from 'lodash'
import React, {FC, useMemo, useCallback} from 'react'

import {MomentRange} from '../../../shared/selectors/time'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {usePlanningChartStartEnd} from '../dailyPlanning/PlanningChartStartEndProvider'
import {useDateScale} from '../../../shared/helpers/scale'

const MIN_SELECTED_HOURS = 12
const DEFAULT_MAX_SELECTED_HOURS_DESKTOP = 7 * 24
const DEFAULT_MAX_SELECTED_HOURS_MOBILE = 3 * 24
const DEFAULT_MAX_SELECTED_HOURS_TABLET = 5 * 24
// Delay in ms to wait before calling onRangeChangeComplete after the user has stopped dragging the slider
const ON_RANGE_CHANGE_COMPLETE_DELAY = 300

interface PlannerDayRangeSliderProps {
  selectedRange: MomentRange
  setSelectedRange: (range: MomentRange) => void
  onRangeChangeComplete: (range: MomentRange) => void
  roundRangeOnResize: (key: SliderKey, range: MomentRange) => MomentRange
  baseRange: MomentRange
  stepsPerHour: number
  ['data-test-id']?: string
}

export const PlannerDayRangeSlider: FC<PlannerDayRangeSliderProps> = React.memo(
  ({
    selectedRange,
    setSelectedRange,
    onRangeChangeComplete,
    baseRange,
    roundRangeOnResize,
    stepsPerHour,
    'data-test-id': dataTestId = 'production_planning_slider'
  }) => {
    const {timezone_id: timezoneId} = usePlantConfig()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

    const {startOfPlan, endOfPlan, totalSteps} = usePlanningChartStartEnd()

    // Horizontal time scale
    const xScaleCells = useDateScale({
      domain: [startOfPlan, endOfPlan],
      range: [0, totalSteps]
    })

    const defaultMaxSelectedHours = isMobile
      ? DEFAULT_MAX_SELECTED_HOURS_MOBILE
      : isTablet
        ? DEFAULT_MAX_SELECTED_HOURS_TABLET
        : DEFAULT_MAX_SELECTED_HOURS_DESKTOP

    const throttledOnRangeChangeComplete = useMemo(
      () => throttle(onRangeChangeComplete, ON_RANGE_CHANGE_COMPLETE_DELAY),
      [onRangeChangeComplete]
    )

    const memoizedOptions = useMemo(
      () => ({
        hoursInStep: stepsPerHour,
        minSelectedHours: MIN_SELECTED_HOURS,
        maxSelectedHours: defaultMaxSelectedHours
      }),
      [stepsPerHour, defaultMaxSelectedHours]
    )

    const handleRangeChange = useCallback(
      (range: MomentRange) => {
        throttledOnRangeChangeComplete(range)
        setSelectedRange(range)
      },
      [throttledOnRangeChangeComplete, setSelectedRange]
    )

    return (
      <DayRangeSlider
        {...spreadDataTestId(dataTestId)}
        options={memoizedOptions}
        setSelectedRange={handleRangeChange}
        selectedRange={selectedRange}
        xScaleCells={xScaleCells}
        roundRangeOnResize={roundRangeOnResize}
        timezoneId={timezoneId}
        baseRange={baseRange}
        onChangeComplete={onRangeChangeComplete}
      />
    )
  }
)

PlannerDayRangeSlider.displayName = 'PlannerDayRangeSlider'
