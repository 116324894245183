import {dataTestId} from '@hconnect/uikit'
import {Box, Collapse, Skeleton, Stack} from '@mui/material'
import {t} from 'i18next'
import moment, {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CollapsibleCard} from '../../../../shared/components/CollapsibleCard'
import {PlanningChartGantt} from '../../../../shared/components/ganttChart/PlanningChartGantt'
import {PlannerLSSettingsKeys} from '../../../../shared/enums'
import {TimeScaleFn} from '../../../../shared/helpers/scale'
import {toPixel} from '../../../../shared/helpers/utils'
import {useHistoryAssetsQuery} from '../../../../shared/hooks/api'
import {useScheduleQuery} from '../../../../shared/hooks/api/schedules/useScheduleQuery'
import {usePersistingSettingState} from '../../../../shared/hooks/usePersistingSettingState'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {getLocalizedDayMonthTimeFormat} from '../../../../shared/selectors/time'
import {AddEditAssetCommentDialog} from '../comments/AddEditAssetCommentDialog'
import {CommentsSidebar} from '../comments/CommentsSidebar'
import {usePlanningChartScheduleData} from '../PlanningChartScheduleDataProvider'

import {PlanningChartLabels} from './PlanningChartLabels'
import {PlanningChartLegend} from './PlanningChartLegend'
import {PlanningActionsContent} from './scheduleCardActions'

interface ProductionPlanningChartCardProps {
  startOfPlan: Moment
  endOfPlan: Moment
  dateLabelHeight: number
  hourlyPriceHeight: number
  labelColumnWidth: number
  cellHeight: number
  cellWidth: number
  hoursDisplayed: number
  xScale: TimeScaleFn
  hoursList: Moment[]
  shouldShowScheduleItemComments: boolean
  shouldShowAssetComments: boolean
}
const _ProductionPlanningChartCard = React.forwardRef<
  HTMLDivElement,
  ProductionPlanningChartCardProps
>(
  (
    {
      startOfPlan,
      endOfPlan,
      dateLabelHeight,
      hourlyPriceHeight,
      labelColumnWidth,
      cellHeight,
      cellWidth,
      hoursDisplayed,
      xScale,
      hoursList,
      shouldShowScheduleItemComments,
      shouldShowAssetComments
    },
    ref
  ) => {
    const {timezone_id: timezoneId} = usePlantConfig()
    const {
      i18n: {language}
    } = useTranslation()

    const {data: sortedAssets} = useHistoryAssetsQuery({
      timeFrame: [startOfPlan, endOfPlan],
      sorted: true
    })
    const {scheduleId} = usePlanningChartScheduleData()
    const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], scheduleId})
    const scheduleLastSaved = schedule?.lastSaved

    const [{isCommentSidebarOpen}, setIsCommentSidebarOpen] = usePersistingSettingState(
      PlannerLSSettingsKeys.PlanningCommentsSidebarSettings,
      {isCommentSidebarOpen: false}
    )

    const [{isCardOpen}, setIsPlanningCardOpen] = usePersistingSettingState(
      PlannerLSSettingsKeys.PlanningChartCardSettings,
      {isCardOpen: true}
    )
    const toggleCardOpen = () => setIsPlanningCardOpen({isCardOpen: !isCardOpen})

    const toggleCommentsSidebar = () =>
      setIsCommentSidebarOpen({
        isCommentSidebarOpen: !isCommentSidebarOpen
      })

    const closeCommentsSidebar = React.useCallback(
      () => setIsCommentSidebarOpen({isCommentSidebarOpen: false}),
      [setIsCommentSidebarOpen]
    )

    const commentSidebarWidth = Math.max(cellWidth * hoursDisplayed * 0.25, 500)
    const isCommentSidebarEnabled = shouldShowScheduleItemComments || shouldShowAssetComments

    return (
      <CollapsibleCard
        isCollapsed={!isCardOpen}
        onToggleCollapsed={toggleCardOpen}
        title={t('planning.planningSectionTitle')}
        subtitle={
          scheduleLastSaved &&
          t('planning.lastSaved', {
            date: getLocalizedDayMonthTimeFormat(
              moment.utc(scheduleLastSaved.savedAt),
              timezoneId,
              language
            ),
            name: scheduleLastSaved.savedBy
          })
        }
        headerContent={
          <Stack direction="row" sx={{justifyContent: 'flex-end'}}>
            <PlanningActionsContent
              toggleCommentsSidebar={toggleCommentsSidebar}
              isPlanningCardCollapsed={!isCardOpen}
            />
          </Stack>
        }
        {...dataTestId('production_planning_chart_card')}
      >
        <Collapse in={isCardOpen} sx={{position: 'relative'}}>
          <AddEditAssetCommentDialog startOfPlan={startOfPlan} endOfPlan={endOfPlan} />
          {isCommentSidebarEnabled && (
            <CommentsSidebar
              width={commentSidebarWidth}
              isOpen={isCommentSidebarOpen}
              closeSidebar={closeCommentsSidebar}
              isReadOnly={false}
            />
          )}
          <Stack
            sx={{overflow: 'hidden'}}
            spacing={2}
            {...dataTestId('planning_chart_card_content')}
          >
            <PlanningChartLegend
              shouldShowActualPricesLegend
              startOfPlan={startOfPlan}
              endOfPlan={endOfPlan}
            />
            <Stack direction="row">
              <Box sx={{mt: toPixel(dateLabelHeight * 2)}}>
                {sortedAssets ? (
                  <PlanningChartLabels
                    sortedAssets={sortedAssets}
                    hourlyPriceHeight={hourlyPriceHeight}
                    cellHeight={cellHeight}
                    width={labelColumnWidth}
                    shouldShowAssetComments={shouldShowAssetComments}
                    isReadOnly={false}
                    shouldShowPriceInfo={true}
                  />
                ) : (
                  <Stack sx={{pt: toPixel(hourlyPriceHeight)}}>
                    {Array.from({length: 10}).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="text"
                        width={labelColumnWidth}
                        height={cellHeight}
                      />
                    ))}
                  </Stack>
                )}
              </Box>
              <PlanningChartGantt
                ref={ref}
                startOfPlan={startOfPlan}
                endOfPlan={endOfPlan}
                numberOfAssets={sortedAssets?.length}
                dateLabelHeight={dateLabelHeight}
                hourlyPriceHeight={hourlyPriceHeight}
                cellHeight={cellHeight}
                cellWidth={cellWidth}
                hoursDisplayed={hoursDisplayed}
                xScale={xScale}
                hoursList={hoursList}
                isReadOnly={false}
                shouldShowScheduleItemComments={shouldShowScheduleItemComments}
                shouldShowCurrentTime
                shouldRoundCurrentTime
                shouldShowActualPricesIndicator
                shouldShowExactPrices
                shouldShowFixedForOptimizerInfo
                shouldShowColorCodedElectricityPrices
              />
            </Stack>
          </Stack>
        </Collapse>
      </CollapsibleCard>
    )
  }
)
export const ProductionPlanningChartCard = React.memo(_ProductionPlanningChartCard)
ProductionPlanningChartCard.displayName = 'ProductionPlanningChartCard'
