import {dataTestId} from '@hconnect/uikit'
import {HPButton, HPButtonProps} from '@hconnect/uikit/src/lib2'
import {Refresh} from '@mui/icons-material'
import {ButtonProps} from '@mui/material'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useDialog} from '../../../shared/components/providers/DialogProvider'
import {useOptizerSettings} from '../../../shared/hooks/api/optimizer/useOptimizerSettings'
import {RecalculatePlanDialogContent} from '../recalculatePlan/RecalculatePlanDialogContent'

export const StartRecalculationButton = (
  {
    variant,
    backgroundMode
  }: Pick<ButtonProps, 'variant'> & Pick<HPButtonProps, 'backgroundMode'> = {
    variant: 'contained',
    backgroundMode: 'medium'
  }
) => {
  const {t} = useTranslation()

  const {openDialog} = useDialog()

  const {data: optimizerSettings} = useOptizerSettings()

  const handleOpenSettings = useCallback(() => {
    openDialog({
      header: {title: t('optimizer.recalculatePlan')},
      content: <RecalculatePlanDialogContent />,
      dataTestId: 'recalculate_optimizer_dialog'
    })
  }, [openDialog, t])

  return (
    <HPButton
      startIcon={<Refresh />}
      variant={variant}
      backgroundMode={backgroundMode}
      color="secondary"
      disabled={!optimizerSettings}
      onClick={handleOpenSettings}
      {...dataTestId('recalculate_optimized_planning_button')}
    >
      {t('optimizer.recalculateOptimizedPlan')}
    </HPButton>
  )
}
