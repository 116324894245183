import {dataTestId, formatCurrency, formatMWh, formatTons} from '@hconnect/uikit'
import {Column} from '@hconnect/uikit/src/lib2'
import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerDataTable} from '../../../shared/components/PlannerDataTable'
import {CostAvoidanceForRangeData} from '../../../shared/hooks/api'
import {useSelectedOptimizerPlan} from '../SelectedOptimizerPlanProvider'
import {OptimizerScheduleType} from '../shared/enums/optimizerSchedules'

import {OptimizerSchedule, useOptimizerPlanOverview} from './hooks/useOptimizerPlansOverview'
import {OptimizerPlanTableKPICell} from './OptimizerPlansTableKPICell'
import {OptimizerPlansPlanNameCell} from './OptimizerPlanTablePlanName'

export const OptimizerPlansTable = () => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const data = useOptimizerPlanOverview()

  const {selectedInTableScheduleId, setSelectedInTableScheduleId, setVisibleScheduleId} =
    useSelectedOptimizerPlan()

  const renderExpectedCosts = useCallback(
    (data: CostAvoidanceForRangeData) => (
      <>
        {formatCurrency(data.total.electricityCost ?? 0, 2, language)} {data.currency}
      </>
    ),
    [language]
  )
  const renderTotalCementTons = useCallback(
    (data: CostAvoidanceForRangeData) => (
      <>{formatTons(data.total.producedTonnes ?? 0, language, t('common.unit.t'))}</>
    ),
    [language, t]
  )
  const renderTotalMWh = useCallback(
    (data: CostAvoidanceForRangeData) => (
      <>{formatMWh(data.total.electricityConsumed ?? 0, language, t('common.unit.MWh'))}</>
    ),
    [language, t]
  )
  const renderCostPerTon = useCallback(
    (data: CostAvoidanceForRangeData) => {
      if (data.total.producedTonnes === undefined) {
        return <>-</>
      }
      const costPerTon = (data.total.electricityCost ?? 0) / data.total.producedTonnes
      return (
        <>
          {formatCurrency(costPerTon, 2, language)} {data.currency}
        </>
      )
    },
    [language]
  )
  const renderCostPerMWh = useCallback(
    (data: CostAvoidanceForRangeData) => {
      if (data.total.electricityConsumed === undefined) {
        return <>-</>
      }
      const costPerTon = (data.total.electricityCost ?? 0) / data.total.electricityConsumed
      return (
        <>
          {formatCurrency(costPerTon, 2, language)} {data.currency}
        </>
      )
    },
    [language]
  )
  const renderBaseLoad = useCallback(
    (data: CostAvoidanceForRangeData) => (
      <>{formatMWh(data.baseload ?? 0, language, t('common.unit.MWh'))}</>
    ),
    [language, t]
  )

  const tableColumns: Column<OptimizerSchedule>[] = useMemo(
    () => [
      {
        key: 'name',
        label: t('optimizer.plan'),
        customTemplate: (schedule) => <OptimizerPlansPlanNameCell schedule={schedule} />
      },
      {
        key: 'expectedCosts',
        label: t('common.expectedCosts'),
        customTemplate: (schedule) => (
          <OptimizerPlanTableKPICell render={renderExpectedCosts} schedule={schedule} />
        )
      },
      {
        key: 'totalCementTons',
        label: t('common.producedTons'),
        customTemplate: (schedule) => (
          <OptimizerPlanTableKPICell render={renderTotalCementTons} schedule={schedule} />
        )
      },
      {
        key: 'mwhConsumed',
        label: t('electricity.mwhConsumed'),
        customTemplate: (schedule) => (
          <OptimizerPlanTableKPICell render={renderTotalMWh} schedule={schedule} />
        )
      },
      {
        key: 'costsPerTon',
        label: t('electricity.costsPerTon'),
        customTemplate: (schedule) => (
          <OptimizerPlanTableKPICell render={renderCostPerTon} schedule={schedule} />
        )
      },
      {
        key: 'costsPerMWh',
        label: t('electricity.costsPerMWh'),
        customTemplate: (schedule) => (
          <OptimizerPlanTableKPICell render={renderCostPerMWh} schedule={schedule} />
        )
      },
      {
        key: 'baseLoad',
        label: t('electricity.baseLoad'),
        customTemplate: (schedule) => (
          <OptimizerPlanTableKPICell render={renderBaseLoad} schedule={schedule} />
        )
      }
    ],
    [
      t,
      renderCostPerMWh,
      renderCostPerTon,
      renderExpectedCosts,
      renderTotalCementTons,
      renderTotalMWh,
      renderBaseLoad
    ]
  )

  const checkIsRowSelected = useCallback(
    (row: OptimizerSchedule) => {
      if (row.type === OptimizerScheduleType.Calculating) {
        return false
      }

      return (
        row.scheduleId === selectedInTableScheduleId ||
        (selectedInTableScheduleId === undefined && row.type === OptimizerScheduleType.Manual)
      )
    },
    [selectedInTableScheduleId]
  )

  const isRowClickable = useCallback(
    (row: OptimizerSchedule) => row.type !== OptimizerScheduleType.Calculating,
    []
  )

  const onRowClick = useCallback(
    (_e: React.MouseEvent<Element, MouseEvent>, row: OptimizerSchedule) => {
      if (row.type === OptimizerScheduleType.Calculating) {
        // should not be clickable
        return
      }
      setSelectedInTableScheduleId(
        row.type === OptimizerScheduleType.Manual ? undefined : row.scheduleId
      )
      setVisibleScheduleId(row.type === OptimizerScheduleType.Manual ? undefined : row.scheduleId)
    },
    [setSelectedInTableScheduleId, setVisibleScheduleId]
  )

  const keyExtractor = useCallback(
    (row: OptimizerSchedule) =>
      String(row.type === OptimizerScheduleType.Calculating ? row.optimizerRunId : row.scheduleId),
    []
  )

  return (
    <PlannerDataTable
      {...dataTestId('optimizer_plans_table')}
      columns={tableColumns}
      data={data}
      onRowClick={onRowClick}
      keyExtractor={keyExtractor}
      emptyMessage={t('demand.noData')}
      isRowClickable={isRowClickable}
      isRowSelected={checkIsRowSelected}
    />
  )
}
