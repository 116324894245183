import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'

import {usePeakLoadWindowsQuery} from '../../../../shared/hooks/api'
import {useCurrentScheduleQuery} from '../../../../shared/hooks/api/schedules/useCurrentScheduleQuery'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {Electricity} from '../../../../shared/interfaces/api'
import {getPeakLoadWindows} from '../../../../shared/selectors/electricity'

import {ElectricityPurchaseConfirmation} from './ElectricityPurchaseConfirmation'
import {SubmissionButton} from './SubmissionButton'

interface SubmitPlanSectionProps {
  electricity: Electricity | undefined
  isSubmitAvailable: boolean
  startOfPlan: Moment
  endOfPlan: Moment
}

export const SubmitPlanSection: React.FC<SubmitPlanSectionProps> = React.memo(
  ({isSubmitAvailable, electricity, startOfPlan, endOfPlan}) => {
    const {timezone_id: timezoneId} = usePlantConfig()
    const powerConsumptionQuarterly = electricity?.planned

    const {data: schedule} = useCurrentScheduleQuery({
      range: [startOfPlan, endOfPlan],
      useErrorBoundary: false
    })

    const submissionInfo = schedule?.submissionInfo

    const [showConfirmation, setShowConfirmation] = React.useState(false)
    const {data: peakLoadWindows} = usePeakLoadWindowsQuery(
      [
        moment.utc(submissionInfo?.start).tz(timezoneId),
        moment.utc(submissionInfo?.end).tz(timezoneId)
      ],
      {
        enabled: !!submissionInfo
      }
    )

    const peakLoadWindowsData = useMemo(() => {
      if (powerConsumptionQuarterly && submissionInfo && peakLoadWindows) {
        const submissionStart = moment.utc(submissionInfo.start).tz(timezoneId)
        const submissionEnd = moment.utc(submissionInfo.end).tz(timezoneId)
        return getPeakLoadWindows({
          peakLoadWindows: peakLoadWindows.filter(
            (plw) =>
              submissionEnd.isSameOrAfter(plw.start) && submissionStart.isSameOrBefore(plw.end)
          ),
          powerConsumption: powerConsumptionQuarterly
        })
      }
      return undefined
    }, [peakLoadWindows, powerConsumptionQuarterly, submissionInfo, timezoneId])

    const isSubmitDisabled =
      (peakLoadWindowsData && peakLoadWindowsData.some((plw) => plw.violated)) ||
      !schedule ||
      !submissionInfo

    return (
      <>
        {showConfirmation && submissionInfo && electricity && (
          <ElectricityPurchaseConfirmation
            show={showConfirmation}
            submissionInfo={submissionInfo}
            electricity={electricity}
            onClose={() => setShowConfirmation(false)}
          />
        )}
        {isSubmitAvailable && (
          <SubmissionButton
            id="submit-plan-button"
            disabled={isSubmitDisabled}
            onClick={() => setShowConfirmation(true)}
          />
        )}
      </>
    )
  }
)
