import {dataTestId} from '@hconnect/uikit'
import {Bolt} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export interface SubmissionButtonProps {
  onClick: () => void
  disabled?: boolean
  id?: string
}

export const SubmissionButton: React.FC<SubmissionButtonProps> = ({onClick, id, disabled = false}) => {
  const {t} = useTranslation()

  return (
    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
      <Button
        startIcon={<Bolt />}
        variant="text"
        sx={{color: 'primary.light'}}
        id={id}
        {...dataTestId('button-schedule-save-and-submit')}
        disabled={disabled}
        onClick={onClick}
      >
        {t('planning.submitSchedule')}
      </Button>
    </Box>
  )
}
