import {dataTestId} from '@hconnect/uikit'
import {HPButton} from '@hconnect/uikit/src/lib2'
import {Upload} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'

import {useTrackPlannerEvent} from '../../../shared/helpers/trackPlannerEvents'
import {useAcceptOptimizedPlan} from '../../../shared/hooks/api/schedules/useAcceptOptimizedPlan'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {useSelectedOptimizerPlan} from '../SelectedOptimizerPlanProvider'

export const SendToOptimizedPlanButton = () => {
  const {visibleScheduleId} = useSelectedOptimizerPlan()

  const plantCode = useUrlParam('plantCode')
  const trackPlannerEvent = useTrackPlannerEvent()

  const {mutate: acceptOptimizedPlan} = useAcceptOptimizedPlan()

  const handleAcceptOptimizedPlan = () => {
    if (visibleScheduleId === undefined) {
      throw new Error('Can send optimized plan only if it is selected')
    }
    trackPlannerEvent({name: 'onAcceptOptimizedPlanFromDetailView'})
    acceptOptimizedPlan({plantCode, scheduleId: visibleScheduleId})
  }

  const {t} = useTranslation()
  return (
    <>
      <HPButton
        size="large"
        variant="contained"
        disabled={visibleScheduleId === undefined}
        startIcon={<Upload fontSize="small" />}
        onClick={handleAcceptOptimizedPlan}
        backgroundMode="medium"
        {...dataTestId('accept_optimized_plan_button')}
      >
        {t('optimizer.sendToProductionPlan')}
      </HPButton>
    </>
  )
}
