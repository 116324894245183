import {dataTestId} from '@hconnect/uikit'
import {Box, Stack, useTheme} from '@mui/material'
import {useQueryErrorResetBoundary} from '@tanstack/react-query'
import React, {useCallback, useLayoutEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerErrorBoundary} from '../../shared/components/errorBoundary/PlannerErrorBoundary'
import {moveElementsHorizontally} from '../../shared/components/ganttChart/chart.helpers'
import {useGanttChartCells} from '../../shared/components/ganttChart/useGanttChartCells'
import {ScheduleCostAvoidanceDialogProvider} from '../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {SelectedAssetCommentProvider} from '../../shared/components/providers/SelectedAssetCommentProvider'
import {CommentsCategory, PlannerFeature} from '../../shared/enums'
import {useDateScale} from '../../shared/helpers/scale'
import {calculateRangeWithinBoundary} from '../../shared/helpers/utils'
import {useCommentsPermissions} from '../../shared/hooks/permissions'
import {useElementWidth} from '../../shared/hooks/useElementWidth'
import {useMediaQueryBreakpoints} from '../../shared/hooks/useMediaQueryBreakpoints'
import {usePlannerFeature} from '../../shared/hooks/usePlannerFeature'
import {useScrollDrag} from '../../shared/hooks/useScrollDrag'

import {CostAvoidanceDialog} from './dailyPlanning/costAvoidance/CostAvoidanceDialog'
import {PlanningElectricityChartCard} from './dailyPlanning/electricity-chart/PlanningElectricityChartCard'
import {KPIsSection} from './dailyPlanning/kpi-section/KPIsSection'
import {usePlanningChartStartEnd} from './dailyPlanning/PlanningChartStartEndProvider'
import {ProductionPlanningChartCard} from './dailyPlanning/scheduleCard/PlanningChartCard'
import {StockLevelsCard} from './dailyPlanning/stockLevels/StockLevelsCard'
import {ProductionPlanningSlider} from '../../shared/components/ProductionPlanningSlider'

const _ProductionPlanningChartContainer: React.FC = () => {
  const {t} = useTranslation()
  const {spacing} = useTheme()
  const {sm: isMobile} = useMediaQueryBreakpoints()
  const {hoursRange, setHoursRange, hoursList, stepsPerHour, totalSteps, startOfPlan, endOfPlan} =
    usePlanningChartStartEnd()
  const areCommentsEnabled = usePlannerFeature(PlannerFeature.PlanningComments)
  const {canViewComments: canViewScheduleItemComments} = useCommentsPermissions(
    CommentsCategory.AssetOperationTimes
  )
  const {canViewComments: canViewAssetComments} = useCommentsPermissions(CommentsCategory.Assets)
  const shouldShowScheduleItemComments = areCommentsEnabled && canViewScheduleItemComments
  const shouldShowAssetComments = areCommentsEnabled && canViewAssetComments

  const [planningLayoutWrapperRef, planningLayoutWidth] = useElementWidth()
  const cardXPadding = parseInt(spacing(isMobile ? 2 : 3))
  // calculating available width for gantt and electricity chart
  const availableWidth = planningLayoutWidth > 0 ? planningLayoutWidth - 2 * cardXPadding : 0
  // constants for layout of the Gantt chart
  const labelColumnWidth = isMobile ? 100 : 120
  const dateLabelHeight = 24
  const hourlyPriceHeight = 30
  const cellHeight = 50
  const chartWidth = availableWidth === 0 ? 0 : availableWidth - labelColumnWidth
  const {updateGanttCells, cellWidth, cellsDisplayed} = useGanttChartCells({
    hoursDisplayed: hoursRange[1] - hoursRange[0],
    cellsPerHour: stepsPerHour,
    chartWidth
  })

  const ganttChartRef = useRef<HTMLDivElement>(null)
  const dragScrollWrapperRef = useRef<HTMLDivElement>(null)

  const [immediateHourOffset] = hoursRange

  // Horizontal time scale
  const xScale = useDateScale({
    domain: [startOfPlan, endOfPlan],
    range: [0, cellWidth * totalSteps]
  })

  const updateHourRangeOnScrollOrDrag = useCallback(
    (delta: number) => {
      const currentOffset = immediateHourOffset + delta
      const newRange = calculateRangeWithinBoundary(
        [currentOffset, currentOffset + cellsDisplayed],
        [0, totalSteps]
      )
      setHoursRange(newRange)
    },
    [immediateHourOffset, cellsDisplayed, setHoursRange, totalSteps]
  )
  // we need this effect to move the charts horizontally when the slider is moved
  useLayoutEffect(() => {
    moveElementsHorizontally(cellWidth * immediateHourOffset, [ganttChartRef])
  }, [cellWidth, immediateHourOffset])

  useScrollDrag({
    ref: dragScrollWrapperRef,
    callback: updateHourRangeOnScrollOrDrag,
    stepWidth: cellWidth,
    multiplier: 4,
    isDragEnabled: false
  })

  const {reset} = useQueryErrorResetBoundary()

  return (
    <Box {...dataTestId('planning_chart_container')} ref={planningLayoutWrapperRef}>
      <Stack spacing={2}>
        <ProductionPlanningSlider updateGanttCells={updateGanttCells} />
        <PlannerErrorBoundary
          contextName="Stock levels card"
          onRetry={reset}
          title={t('error.storageLevelCardBoundaryError')}
          fallbackTestId="stock_levels_card_fallback"
        >
          <StockLevelsCard
            startOfPlan={startOfPlan}
            endOfPlan={endOfPlan}
            cellWidth={cellWidth}
            totalSteps={totalSteps}
            immediateHourOffset={immediateHourOffset}
          />
        </PlannerErrorBoundary>
        <Stack spacing={2} ref={dragScrollWrapperRef} {...dataTestId('chart_scroll_drag_wrapper')}>
          <ScheduleCostAvoidanceDialogProvider>
            <PlannerErrorBoundary
              contextName="Production planning card"
              onRetry={reset}
              title={t('error.productionPlanningCardBoundaryError')}
              fallbackTestId="production_planning_card_fallback"
            >
              <SelectedAssetCommentProvider>
                <CostAvoidanceDialog startOfPlan={startOfPlan} endOfPlan={endOfPlan} />
                <ProductionPlanningChartCard
                  ref={ganttChartRef}
                  startOfPlan={startOfPlan}
                  endOfPlan={endOfPlan}
                  labelColumnWidth={labelColumnWidth}
                  dateLabelHeight={dateLabelHeight}
                  hourlyPriceHeight={hourlyPriceHeight}
                  cellHeight={cellHeight}
                  cellWidth={cellWidth}
                  hoursDisplayed={cellsDisplayed}
                  xScale={xScale}
                  hoursList={hoursList}
                  shouldShowScheduleItemComments={shouldShowScheduleItemComments}
                  shouldShowAssetComments={shouldShowAssetComments}
                />
              </SelectedAssetCommentProvider>
            </PlannerErrorBoundary>
            <PlannerErrorBoundary
              contextName="Electricity chart card"
              onRetry={reset}
              title={t('error.electricityChartCardBoundaryError')}
              fallbackTestId="electricity_chart_card_fallback"
            >
              <PlanningElectricityChartCard
                startOfPlan={startOfPlan}
                endOfPlan={endOfPlan}
                hoursList={hoursList}
                cellWidth={cellWidth}
                labelColumnWidth={labelColumnWidth}
                cellsDisplayed={cellsDisplayed}
              />
            </PlannerErrorBoundary>
          </ScheduleCostAvoidanceDialogProvider>
        </Stack>
        <PlannerErrorBoundary
          contextName="KPIs section"
          onRetry={reset}
          title={t('error.kpiCardBoundaryError')}
          fallbackTestId="kpi_card_fallback"
        >
          <KPIsSection />
        </PlannerErrorBoundary>
      </Stack>
    </Box>
  )
}

export const ProductionPlanningChartContainer = React.memo(_ProductionPlanningChartContainer)
