import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {useTheme} from '@mui/material'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {LegendItem, Legend} from '../../../shared/components/Legend'

const {accent2} = customThemeConstants().palette

export const OptimizerPrioritiesLegend: FC = () => {
  const {palette} = useTheme()
  const {t} = useTranslation()

  return (
    <Legend>
      <LegendItem
        label={t('optimizer.currentSetting')}
        indicator={{
          variant: 'circle',
          style: {background: palette.text.primary}
        }}
      />
      <LegendItem
        label={t('optimizer.lastSetting')}
        indicator={{
          variant: 'circle',
          style: {background: palette.grey[500], border: `2px solid ${accent2}`}
        }}
      />
    </Legend>
  )
}
