import {dataTestId} from '@hconnect/uikit/src/common'
import {HPButton} from '@hconnect/uikit/src/lib2'
import {TuneOutlined} from '@mui/icons-material'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useDialog} from '../../../shared/components/providers/DialogProvider'
import {useOptizerSettings} from '../../../shared/hooks/api/optimizer/useOptimizerSettings'

import {OptimizerSettingsDialogContent} from './OptimizerSettingsDialogContent'

export const OptimizerSettingsButton = () => {
  const {t} = useTranslation()
  const {openDialog} = useDialog()

  const {data: optimizerSettings} = useOptizerSettings()

  const handleOpenSettings = useCallback(() => {
    openDialog({
      header: {title: t('optimizer.optimizerSettings')},
      content: <OptimizerSettingsDialogContent />,
      dataTestId: 'optimizer_settings_dialog'
    })
  }, [openDialog, t])

  return (
    <HPButton
      {...dataTestId('optimizer_settings_button')}
      backgroundMode="medium"
      startIcon={<TuneOutlined />}
      onClick={handleOpenSettings}
      disabled={!optimizerSettings}
    >
      {t('optimizer.settings')}
    </HPButton>
  )
}
