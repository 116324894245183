import {dataTestId} from '@hconnect/uikit'
import {HPToggleButton} from '@hconnect/uikit/src/lib2'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedOptimizerPlan} from '../SelectedOptimizerPlanProvider'

enum OptimizerPlanType {
  Production = 'production',
  Selected = 'selected'
}

export function TogglePlanTypeButton() {
  const {t} = useTranslation()

  const {visibleScheduleId, selectedInTableScheduleId, setVisibleScheduleId} =
    useSelectedOptimizerPlan()

  const handleChangeMode = useCallback(
    (newMode: OptimizerPlanType) => {
      if (selectedInTableScheduleId === undefined) {
        throw new Error('Can change mode only if optimized schedule is selected')
      }
      if (newMode === OptimizerPlanType.Production) {
        setVisibleScheduleId(undefined)
      } else {
        setVisibleScheduleId(selectedInTableScheduleId)
      }
    },
    [selectedInTableScheduleId, setVisibleScheduleId]
  )

  return (
    <HPToggleButton
      {...dataTestId('optimizer_planning_mode_button')}
      value={
        visibleScheduleId === undefined ? OptimizerPlanType.Production : OptimizerPlanType.Selected
      }
      backgroundMode="light"
      disabled={selectedInTableScheduleId === undefined}
      color="primary"
      options={[
        {
          value: OptimizerPlanType.Selected,
          title: t('optimizer.selectedPlan'),
          dataTestId: 'selected_plan_button'
        },
        {
          value: OptimizerPlanType.Production,
          title: t('optimizer.productionPlan'),
          dataTestId: 'production_plan_button'
        }
      ]}
      onChange={handleChangeMode}
    />
  )
}
