import {BarycentricCoordinates, customThemeConstants, LabelStyle} from '@hconnect/uikit/src/lib2'
import {Shuffle, Bolt} from '@mui/icons-material'
import {useTheme} from '@mui/material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as SiloIcon} from '../../../assets/icons/SilosIcon.svg'
import {OptimizerSettings} from '../../../shared/interfaces/api'

export const mapOptimizerSettingsToBarycentricCoordinates = (
  optimizerSettings: OptimizerSettings
): BarycentricCoordinates => [
  optimizerSettings.switchCostPriority,
  optimizerSettings.electricityCostPriority,
  optimizerSettings.siloSlackPriority
]
export const mapBarycentricCoordinatesToOptimizerSettings = (
  coordinates: BarycentricCoordinates
): OptimizerSettings => ({
  switchCostPriority: coordinates[0],
  electricityCostPriority: coordinates[1],
  siloSlackPriority: coordinates[2]
})

const {accent2, accent1} = customThemeConstants().palette

export const useOptimizerPrioritiesStyles = () => {
  const {t} = useTranslation()
  const {palette} = useTheme()

  return useMemo(
    () =>
      [
        {
          color: palette.primary.main,
          label: t('optimizer.switchCostPriorityLabel'),
          icon: <Shuffle />
        },
        {color: accent2, label: t('optimizer.electricityCostPriorityLabel'), icon: <Bolt />},
        {
          color: accent1,
          label: t('optimizer.siloSlackPriorityLabel'),
          // custom height to fit the MUI icon size
          icon: <SiloIcon fill={accent1} height={16} />
        }
      ] as [LabelStyle, LabelStyle, LabelStyle],
    [palette, t]
  )
}
